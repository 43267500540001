export const attributesSetter = (
  el: HTMLElement,
  attributes: Record<string, string | undefined>,
) => {
  Object.entries(attributes).forEach(([name, value]) => {
    if (value) {
      el.setAttribute(name, value);
    }
  });
};
