// @ts-ignore
import { nanoid } from 'nanoid/non-secure';
import { checkWLDomain } from 'cf-common/src/whitelabel';
import {
  getApiHostname,
  getPortWithSeparator,
  getTopLevelDomain,
} from 'cf-common/src/domain';
import { IS_DEBUG, IS_PRODUCTION_BUILD } from 'cf-common/src/environment';
import { getUserId } from 'cf-common/src/userId';
import { DomainCookie } from 'cf-common/src/DomainCookie';
import { LSKey, LS } from 'cf-common/src/localStorage';

export const ANALYTICS_VERSION = '0.3';

export const CF_KEY = {
  Clid: '__cf_clid',
  Ssid: '__cf_ssid',
  Ref: '__cf_url_ref',
  YMClid: '__cf_ym_clid',
  VisualizationKey: 'cf-visualization',
  GdprAccepted: '__cf_gdpr_accepted',
  LPModuleVisit: '__cf_lp_module_visit/',
  CountyCodeCookie: 'country',
};

export const isWhitelabelDomain = checkWLDomain(window.location.hostname);

export const getDebugMode = () => !IS_PRODUCTION_BUILD || IS_DEBUG;

export const getVisualizationMode = () => LS.getRaw(LSKey.visualization);

export const apiHostname = getApiHostname();

// Always add cookie for top level domain - 2 level
const getCookieHostname = (): string =>
  `${getTopLevelDomain(2)}${getPortWithSeparator()}`;
export const cookieHostname = getCookieHostname();

const highlevelHostnameCookie = new DomainCookie(cookieHostname);

/*
  null - is not accepted or declined;
  true - accepted all;
  false - declined non-essential.
*/
export const isGdprAccepted = (): null | boolean => {
  if (!IS_PRODUCTION_BUILD) {
    return true;
  }

  const isAccepted = JSON.parse(
    highlevelHostnameCookie.get(CF_KEY.GdprAccepted) || 'null',
  );

  // Accept GDPR for WL and already signed in users
  if (!isAccepted && (isWhitelabelDomain || getUserId())) {
    highlevelHostnameCookie.set(CF_KEY.GdprAccepted, JSON.stringify(true));
    return true;
  }

  if (isAccepted === null) {
    /*
      GDPR modal should be disabled for
      - docs - Intercom part;
      - shared-flow - user shouldn't see modal on embedded flow;
      - messenger-extensions - user shouldn't see modal on embedded extension.
    */
    return window.__cf_ignore_gdpr ? false : isAccepted;
  }

  return isAccepted;
};

export const acceptGdpr = (allCookies: boolean) => {
  highlevelHostnameCookie.set(CF_KEY.GdprAccepted, JSON.stringify(allCookies));
};

export const getSsid = (): string => {
  const savedSsid = sessionStorage.getItem(CF_KEY.Ssid);
  if (savedSsid) {
    return savedSsid;
  }

  const simpleSessionId = nanoid();
  sessionStorage.setItem(CF_KEY.Ssid, simpleSessionId);
  return simpleSessionId;
};

export const getClid = (): string => {
  if (!isGdprAccepted()) {
    return getSsid();
  }

  const savedClid = highlevelHostnameCookie.get(CF_KEY.Clid);
  if (savedClid) {
    return savedClid;
  }

  const clid = getSsid();
  highlevelHostnameCookie.set(CF_KEY.Clid, clid);
  return clid;
};

const getParentFrameHostname = (): string | null => {
  const parentHostname = window.parent?.location?.hostname;
  const otherDomainParent =
    parentHostname && parentHostname !== window.location.hostname;
  return otherDomainParent ? parentHostname : null;
};

export const getLocationReferrer = (): {
  ref: string | null;
  alreadyVisited: boolean;
} => {
  const alreadyVisited = !!LS.getRaw(LSKey.ref);
  LS.setRaw(LSKey.ref, '__second_visit');

  const url = new URL(window.location.href);
  const ref =
    url.searchParams.get('ref') ||
    url.searchParams.get('interruptedHref') ||
    url.searchParams.get('video_ref') ||
    url.searchParams.get('utm_referrer') ||
    document.referrer ||
    getParentFrameHostname() ||
    null;
  return { ref, alreadyVisited };
};

export const getLandingAbt = (): string | null => {
  const url = new URL(window.location.href);
  const abt = url.searchParams.get('abt');
  return abt;
};

export const getEventCategory = (): string => {
  const domainParts = (
    LS.getRaw(LSKey.WL_DOMAIN_MOCK) || window.location.hostname
  ).split('.');
  const subdomain = domainParts.slice(0, domainParts.length - 2).join('_');
  return `${subdomain || 'landing'} page`;
};

export const screenSize = (() => {
  try {
    return `${window.screen.availWidth}x${window.screen.availHeight}/${window.screen.width}x${window.screen.height}`;
  } catch {
    return 'Unknown';
  }
})();

export const getCookie = (cookie: string): string | null =>
  highlevelHostnameCookie.get(cookie);

const queryParamsToClear = ['conversationId'];
const REMOVED_PLACEHOLDER = '__removed__';

const anonymousLocationsCache: Record<string, string> = {};

export const getAnonymousLocation = (): string => {
  const cachedLocation = anonymousLocationsCache[window.location.href];
  if (cachedLocation) {
    return cachedLocation;
  }

  let { search } = window.location;

  if (search) {
    const queryParams = new URLSearchParams(search);
    queryParamsToClear.forEach((name) => {
      if (queryParams.has(name)) {
        queryParams.set(name, REMOVED_PLACEHOLDER);
      }
    });
    search = `?${queryParams.toString()}`;
  }

  anonymousLocationsCache[
    window.location.href
  ] = `${window.location.origin}${window.location.pathname}${search}`;
  return anonymousLocationsCache[window.location.href];
};

export const isLiveChatWebView = () => LS.get(LSKey.isLiveChatWebView);
