// using for autotests only in debug mode

const ACTIVE_REQUESTS = new Set();

export const initRequestsMonitoringInterceptors = () => {
  const { fetch: originalFetch } = window;
  window.fetch = (...args) => {
    const [resource] = args;
    ACTIVE_REQUESTS.add(resource);

    return originalFetch(...args)
      .then((response) => {
        ACTIVE_REQUESTS.delete(resource);
        return response;
      })
      .catch((reason) => {
        ACTIVE_REQUESTS.delete(resource);
        throw new Error(reason);
      });
  };

  /* eslint-disable func-names, prefer-rest-params */
  const originOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function () {
    const [, resource] = arguments;
    ACTIVE_REQUESTS.add(resource);
    this.addEventListener('load', function () {
      ACTIVE_REQUESTS.delete(resource);
    });
    originOpen.apply(this, arguments as any);
  };
  /* eslint-enable func-names, prefer-rest-params */

  // for autotests
  window.getRequestsList = () => {
    return Array.from(ACTIVE_REQUESTS) as string[];
  };
};
