import type { FacebookAdCampaignCategory, Platform } from '@globals';
import { SchemedStorage } from './schemedStorage';
import { SortTypesIds } from '@pages/BotListPage/components/WorkspaceList/helpers';

export const enum LSKey {
  // configs
  LOG_LEVEL_CONSOLE = 'LOG_LEVEL_CONSOLE',
  CF_ENV = 'CF_ENV',
  WL_DOMAIN_MOCK = 'WL_DOMAIN_MOCK',
  LANDING_DOMAIN_MOCK = 'LANDING_DOMAIN_MOCK',
  // auth
  token = 'token',
  authed = 'authed',
  // analytics
  ref = '__cf_url_ref',
  visualization = 'cf-visualization',
  thirdPartyAnalytics = 'third-party-analytics',
  // ///
  savedUserId = 'user-id-saved',
  userIdBeforeLogout = '_cf__user_id_before_logout',
  isDebug = 'cf-debug',
  isLocalMonitoring = 'cf-local-monitoring',
  flowBuilderDebug = '__cf_flow_builder_debug',
  botsListBannerIndex = '_cf__botsListBannerIndex',
  /** @deprecated (no usages) */
  botsReachedLimitDismissed = '_cf__bots_reached_limit_dismissed',
  fbAdsShowCampaignCategory = '_cf__fb_show_campaign_category',
  /** @deprecated (no usages) */
  fbPluginsCopyState = '_cf__fb_plugins_copy_state',
  fbAdAccountId = '_cf__fb_ad_account_id',
  lastUsedFacebookShopCatalogue = '_cf__last_used_facebook_shop_catalog',
  verificationEmailSentAt = '_cf__verification_email_sent_at',
  wizardActiveStep = '_cf__wizard_active_step',
  /** @deprecated (no usages) */
  introQuestionsData = '_cf__intro_questions_data',
  /** @deprecated (no usages) */
  introQuestionsFormData = '_cf__intro_questions_form_data',
  /** @deprecated (no usages) */
  skipIntroQuestions = '_cf_skip_intro_questions',
  introQuestionsBotSessionId = '_cf__intro_questions_bot_session_id',
  showFlowBuilderOnMobile = '_cf__show_flow_builder_on_mobile',
  fastI18Nrefresh = '_cf__fast_i18n_refresh',
  hideChromeAlert = '_cf_hide_chrome_alert',
  gestureNavigationType = '__cf_flow_builder_gesture_navigation_type',
  syncFbRender = 'cf-fb-sync-render',
  /* flag to detect login from admin panel */
  syntheticLogin = '__cf_synthetic_login',
  autotestLogin = '__cf_autotest_login',
  sendToMessengerInJsonClicked = '_cf_send-to-messenger_in_json_clicked',
  monetizationProWarringShowed = '_cf_monetization_pro_warring_showed',
  keywordsTabPlatform = '_cf_keywords_tab_platform',
  /** @deprecated (no usages) */
  qrTooltipShowed1 = `qr_tool_tip_showed_1`,
  qrTooltipShowed2 = `qr_tool_tip_showed_2`,
  updateBlocks = 'updateBlocks',
  testTemplate = '_cf__test_template',
  forceCanvas = '_cf_force_canvas',
  /**
   * Требуются для тестирования
   * start
   */
  detailedPricingDates = '_cf_detailed_pricing_dates',
  waBroadcastsTriggerIntervals = '_cf_whatsapp_broadcasts_trigger_intervals',
  mockGoogleMaps = '_cf_mock_google_maps',
  /**
   * end
   */

  firstSessionStep = '_cf_first_session_step',
  firstSessionTabPlatform = '_cf_first_session_tab_platform',
  guestId = '_cf_guest_id',
  collectUserDataTestTimeouts = '__cf_collect_user_data_test_timeouts',
  followUpTestTimeouts = '__cf_follow_up_test_timeouts',
  botSortType = '_cf_botSortType',
  /**
   * To enter the flow builder and give the user a demo
   */
  skipFirstSession = '_cf_skip_first_session',
  onboardingPreopenedTip = '_cf_onboarding_preopened_tip',
  isLiveChatLeftPanelShow = '_cf_is_live_chat_left_panel_show',
  isLiveChatRightPanelShow = '_cf_is_live_chat_right_panel_show',
  isLiveChatNotesExpand = '_cf_is_live_chat_notes_expand',

  /**
   * start mobile aplication keys
   */
  isLiveChatWebView = '_cf_live_chat_web_view',
  isLiveChatWebViewAudioAvailable = '_cf_live_chat_web_view_audio_available',
  /**
   * end mobile aplication keys
   */
  testDialogsQtyForSendActiveUserEvent = '_cf_test_dialogs_qty_for_send_active_user_event',
}

export type LS_SCHEMA = {
  [LSKey.LOG_LEVEL_CONSOLE]: string; // raw
  [LSKey.CF_ENV]: string; // raw
  [LSKey.WL_DOMAIN_MOCK]: string; // raw
  [LSKey.LANDING_DOMAIN_MOCK]: string; // raw

  [LSKey.ref]: string; // raw
  [LSKey.visualization]: string; // raw
  [LSKey.thirdPartyAnalytics]: string; // raw
  [LSKey.token]: string; // raw
  [LSKey.authed]: boolean; // raw
  [LSKey.savedUserId]: string; // raw
  [LSKey.userIdBeforeLogout]: string; // raw
  [LSKey.isDebug]: boolean; // raw
  [LSKey.isLocalMonitoring]: boolean; // raw
  [LSKey.botsListBannerIndex]: number;
  [LSKey.botsReachedLimitDismissed]: any;
  [LSKey.fbAdsShowCampaignCategory]: FacebookAdCampaignCategory;
  [LSKey.fbPluginsCopyState]: any;
  [LSKey.fbAdAccountId]: number;
  [LSKey.lastUsedFacebookShopCatalogue]: string;
  [LSKey.verificationEmailSentAt]: number;
  [LSKey.wizardActiveStep]: number;
  [LSKey.introQuestionsData]: any;
  [LSKey.introQuestionsFormData]: any;
  [LSKey.skipIntroQuestions]: boolean;
  [LSKey.introQuestionsBotSessionId]: string;
  [LSKey.fastI18Nrefresh]: boolean;
  [LSKey.showFlowBuilderOnMobile]: boolean;
  [LSKey.hideChromeAlert]: number;
  [LSKey.gestureNavigationType]: string; // raw (GestureNavigation)
  [LSKey.syncFbRender]: string;
  [LSKey.syntheticLogin]: number;
  [LSKey.autotestLogin]: number;
  [LSKey.qrTooltipShowed2]: boolean;
  [LSKey.updateBlocks]: string; // raw (`event ${Math.random()}`)
  [LSKey.forceCanvas]: boolean;
  // [`users-${res.block_id}`]: string, not implemented until regexp literal type support
  // [`auto-${res.block_id}`]: string, not implemented until regexp literal type support
  // [`${this.flow.id}_viewport`]: {scale: number, x: number, y: number}, not implemented until regexp literal type support
  // [`__cf_uniq_seen_event_ls_key_${entrypointId}`]: boolean, not implemented until regexp literal type support
  // [`auto-${this.$rootScope.stateParams.blockId}`]: boolean, not implemented until regexp literal type support
  // [`_cf_user-tab-cols-order-${botId.sustr(0,8)}`]: {cols, sortOpt}, not implemented until regexp literal type support
  [LSKey.monetizationProWarringShowed]: string[];
  [LSKey.testTemplate]: string; // raw
  [LSKey.detailedPricingDates]: boolean;
  [LSKey.waBroadcastsTriggerIntervals]: boolean;
  [LSKey.mockGoogleMaps]: boolean;
  [LSKey.firstSessionStep]: number;
  [LSKey.keywordsTabPlatform]: Platform;
  [LSKey.firstSessionTabPlatform]: Platform;
  [LSKey.guestId]: string;
  [LSKey.collectUserDataTestTimeouts]: boolean;
  [LSKey.followUpTestTimeouts]: boolean;
  [LSKey.botSortType]: SortTypesIds;
  [LSKey.skipFirstSession]: boolean;
  [LSKey.isLiveChatWebView]: boolean;
  [LSKey.isLiveChatWebViewAudioAvailable]: boolean;
  [LSKey.onboardingPreopenedTip]: boolean;
  [LSKey.isLiveChatLeftPanelShow]: boolean;
  [LSKey.isLiveChatRightPanelShow]: boolean;
  [LSKey.testDialogsQtyForSendActiveUserEvent]: number;
  [LSKey.isLiveChatNotesExpand]: boolean;
};

export const LS = new SchemedStorage<LS_SCHEMA>(window.localStorage);
