/**
 *  При кропе строки с эмодзи в конце могут остаться байты разрезного эмодзи, которые вызывают падение рендера.
 *  Пробуем энкодить строку и отрезаем лишнее пока не получится.
 */

export const removeBrokenEmojiChars = (rawStr: string) => {
  let str = rawStr;
  let valid = false;
  while (!valid) {
    try {
      encodeURI(str);
      valid = true;
    } catch {
      str = str.substr(0, str.length - 1);
    }
  }
  return str;
};
