import prom from 'promjs';
import { debounce } from 'lodash-es';
import { sendBeaconOrFetch } from '../beaconOrFetch';
import { getApiHostname } from '../domain';
import { IS_PRODUCTION_BUILD } from '../environment';
import { Level, logLocally } from '../logger';
import { Queue } from '../queue';
import { transformMetricsToDTO } from './transform';
import { PrometheusMetricType } from './types';
import { localMonitoring } from '../localMonitoring';

// In some cases this code uses private fields because promjs
// doesn't have ability to get data in object instead of prometheus string

const registry = prom();
const apiHostname = getApiHostname();
const prometheusMethodsToTriggerQueue = new Set([
  'collect',
  'set',
  'reset',
  'resetAll',
  'observe',
  'dec',
  'sub',
  'inc',
  'add',
]);

const getReadableRegirstryMetrics = () =>
  // @ts-ignore to read internal private field to convert it to own format
  (transformMetricsToDTO(registry.data) || []).filter(
    (metric) => metric.value ?? (metric.values ?? []).filter(Boolean).length,
  );

export const prometheusQueue = new Queue({
  processTimeout: 15000,
  threshold: 1, // no retries
  processQueue: () =>
    new Promise<void>((res, rej) => {
      const metrics = getReadableRegirstryMetrics();
      registry.reset(); // to avoid metrics duplications

      if (!metrics.length) {
        return res();
      }

      localMonitoring(metrics);
      return sendBeaconOrFetch(
        `${apiHostname}/frontend_monitoring/metrics`,
        new Blob([JSON.stringify(metrics)], { type: 'application/json' }),
      )
        .then(() => res())
        .catch(() => rej());
    }),
});

const logCurrentMetricsQueue = debounce(() => {
  const metricsQueue = getReadableRegirstryMetrics();
  if (metricsQueue.length) {
    logLocally(Level.info, 'Metrics added to the queue:', metricsQueue);
  }
}, 1000);

export const PrometheusMetric: PrometheusMetricType = {
  create: new Proxy(registry.create, {
    apply(target, _, args) {
      // @ts-ignore to skip args check
      const metric = target.apply(registry, args);
      return new Proxy(metric, {
        get(target, property) {
          if (prometheusMethodsToTriggerQueue.has(String(property))) {
            // just retrigger queue processing
            prometheusQueue.clear();
            prometheusQueue.push(null);

            if (!IS_PRODUCTION_BUILD) {
              logCurrentMetricsQueue();
            }
          }
          // @ts-ignore to skip property key type check
          return target[property];
        },
      });
    },
  }),
};

export const processRestMetrics = () => prometheusQueue.processRest();

window.addEventListener('beforeunload', () => prometheusQueue.processRest());
window.addEventListener('unload', () => prometheusQueue.processRest());
