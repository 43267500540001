import { log } from './logger';

type EventsWithoutPayload =
  | 'ViewContent'
  | 'Lead'
  | 'StartTrial'
  | 'AddPaymentInfo'
  | 'Contact'
  | 'Schedule'
  | 'CompleteRegistration'
  | 'SubmitApplication';

type CurrentEvents = EventsWithoutPayload | 'Subscribe';

interface SubscribePayload {
  subscription_id: string;
  currency: string;
  value: number;
}

function fbqTrack(
  eventName: 'Subscribe',
  payload: SubscribePayload,
  params: { eventID: string },
): void;
function fbqTrack(
  eventName: EventsWithoutPayload,
  payload: {},
  params: { eventID: string },
): void;
function fbqTrack(
  eventName: CurrentEvents,
  payload: any,
  params: { eventID: string },
): void {
  try {
    window.fbq?.('track', eventName, payload || {}, params);
  } catch (error) {
    log.warn({ error, msg: `[fbq] Can't track ${eventName}` });
  }
}

export { fbqTrack };
