import { sendEvent } from './analytics';
import { getTopLevelDomain } from './domain';

const isRequestBlocked = async () => {
  const analyticsDomain = getTopLevelDomain();
  try {
    const req = await fetch(`${analyticsDomain}/cf-analytics/analytics.js`);
    const text = await req.text();
    return text !== 'thisisbaitfile';
  } catch {
    return true;
  }
};

const isPixelBlocked = () => ({
  ga: !window.ga,
  fb: !window.fbq,
});

const createAdElement = () => {
  const ad = document.createElement('div');
  ad.setAttribute(
    'class',
    'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads text-ad-links ad-text adSense adBlock adContent adBanner',
  );
  ad.setAttribute(
    'style',
    'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;',
  );
  return ad;
};

const isElementBlocked = (element: HTMLElement) => {
  if (
    element.offsetParent === null ||
    element.offsetHeight === 0 ||
    element.offsetLeft === 0 ||
    element.offsetTop === 0 ||
    element.offsetWidth === 0 ||
    element.clientHeight === 0 ||
    element.clientWidth === 0
  ) {
    return true;
  }

  const computedStyles = window.getComputedStyle(element, null);
  return (
    computedStyles &&
    (computedStyles.getPropertyValue('display') === 'none' ||
      computedStyles.getPropertyValue('visibility') === 'hidden')
  );
};

const isDomBlocked = () => {
  const ad = createAdElement();
  window.document.body.appendChild(ad);
  const blocked = isElementBlocked(ad);
  window.document.body.removeChild(ad);
  return blocked;
};

export const sendAdBlockerAnalytics = async () => {
  const request = await isRequestBlocked();
  const dom = isDomBlocked();
  const { ga, fb } = isPixelBlocked();

  sendEvent({
    action: request || dom ? 'enabled' : 'disabled',
    category: 'adblocker',
    propertyBag: {
      request,
      dom,
      ga,
      fb,
    },
  });
};
