import {
  FlowTypes,
  FlowData,
} from 'cf-dashboard/src/modern-components/FlowBuilder/types';
import { isRootBlock } from 'cf-dashboard/src/modern-components/FlowBuilder/consts';
import { Platform } from '@globals';
import { jsonRequest } from '../utils/request';

interface AggregatedFlow {
  id: string;
  title: string;
  blocks: FlowTypes.AggregatedFlowQuery_bot_flowBlocks[];
  redirect_blocks: FlowTypes.AggregatedFlowQuery_bot_flowBlocks[];
  bot_id: string;
  bot_status: FlowTypes.AggregatedFlowQuery_bot_status;
  otn_enabled: false;
  segments: FlowTypes.AggregatedFlowQuery_bot_segments[];
  sharing_params: FlowTypes.AggregatedFlowQuery_bot_flow_sharing_params;
  verified_permissions: FlowTypes.AggregatedFlowQuery_bot_verifiedPermissions[];
  platform: Platform;
}

/**
 * Defaults for Customer Chat purpose (when page isn't connected)
 */
const DEFAULT_PAGE: FlowTypes.AggregatedFlowQuery_bot_status_page_info = {
  __typename: 'Page',
  id: '',
  title: 'Best Page',
  picture: null,
  username: null,
};

const DEFAULT_USER: FlowTypes.AggregatedFlowQuery_me = {
  __typename: 'Admin',
  id: '',
  name: 'John Smith',
  picture: null,
  features: {} as any,
  fbPermissions: {} as any,
};

export const getFlow = async (flowId: string): Promise<FlowData> => {
  const {
    id,
    title,
    bot_id: botId,
    bot_status: botStatus,
    blocks: blocksWithRoot,
    redirect_blocks,
    segments,
    sharing_params: sharingParams,
    verified_permissions: verifiedPermissions,
    otn_enabled,
    platform,
  } = await jsonRequest<AggregatedFlow>(
    `/api/shared/aggregated_flow/${flowId}`,
  );

  let root: any;
  // eslint-disable-next-line prefer-const
  let blocksTitles: FlowTypes.AggregatedFlowQuery_bot_archiveBlocks[] =
    redirect_blocks.map(({ id, title }) => ({
      __typename: 'Block',
      id,
      title,
      reachable: true,
      removed: false,
      is_flow: false,
    }));
  const blocks = blocksWithRoot.filter((bl) => {
    blocksTitles.push({
      __typename: 'Block',
      id: bl.id,
      title: bl.title,
      reachable: true,
      removed: false,
      is_flow: false,
    });
    if (isRootBlock(bl.subtype as string)) {
      root = bl;
      return false;
    }
    return true;
  });

  return {
    id,
    title,
    blocks,
    blocksTitles,
    botId,
    customAiTemplates: null,
    botFeatures: {
      __typename: 'BotFeatures',
      otn_enabled,
      facebook_analytics: false,
      flow_builder: false,
      inline_stats_ro: false,
      livechat_v2: false,
      sync_clone: false,
      flow_stats: false,
      dialogs_pricing_enabled: false,
      hide_analytics_unrecognized_phrases: false,
      show_third_party_analytics: false,
      enable_whatsapp: false,
      enable_ad_comments_reply: false,
      enable_analyze: false,
      kommo: false,
      hubspot: false,
      whatsapp_business_app_integration: false,
    },
    botStatus,
    root,
    segments,
    sharingParams,
    verifiedPermissions,
    // not needed for shared purpose
    groups: [],
    user: DEFAULT_USER,
    bot: {
      __typename: 'Bot',
      title: botStatus.page_info?.title || DEFAULT_PAGE.title,
      status: botStatus,
      instagram: {
        __typename: 'InstagramAccountInfo',
        connected: false,
        connection_problem: null,
        automation_enabled: false,
        business_account: null,
      },
    } as FlowTypes.AggregatedFlowQuery_bot,
    collapsed: false,
    uniqStats: null,
    platform,
  };
};
