import { CollectorType, CounterType, GaugeType, HistogramType } from 'promjs';
import { MetricName } from './names';

// https://prometheus.io/docs/concepts/metric_types/
export enum PrometheusType {
  Gauge = 'gauge',
  Counter = 'counter',
  Histogram = 'histogram',
}

export type PrometheusMetricType = {
  create(
    type: PrometheusType.Gauge,
    name: MetricName,
    help?: string,
  ): GaugeType;
  create(
    type: PrometheusType.Counter,
    name: MetricName,
    help?: string,
  ): CounterType;
  create(
    type: PrometheusType.Histogram,
    name: MetricName,
    help?: string,
    histogramBuckets?: number[],
  ): HistogramType;
};

export type MetricCalculation = Record<
  MetricName,
  {
    type: CollectorType;
    help: string;
    instance: CounterType | GaugeType | HistogramType;
  }
>;
