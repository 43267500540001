import { CSSProperties } from 'react';

const SCREENS = {
  _: 0,
  xs: 480, // => @media (min-width: 480px) { ... }
  sm: 640, // => @media (min-width: 640px) { ... }
  md: 768, // => @media (min-width: 768px) { ... }
  lg: 1024, // => @media (min-width: 1024px) { ... }
  xl: Infinity, // => @media (min-width: 1280px) { ... }
};
const ORDER: (keyof typeof SCREENS)[] = ['_', 'xs', 'sm', 'md', 'lg', 'xl'];

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

export type AdaptiveStyleProperties = PartialRecord<
  keyof typeof SCREENS,
  CSSProperties
>;

export const adaptiveStyle = (styles: AdaptiveStyleProperties) => {
  const currentScreen = ORDER.findIndex(
    (screen) => SCREENS[screen] >= window.innerWidth,
  );
  return ORDER.slice(0, currentScreen).reduce((acc, screen) => {
    return {
      ...acc,
      ...styles[screen],
    };
  }, {});
};
