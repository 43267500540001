// Analytics scripts are external scripts which placed and window.__cf_analytics namespace
export const getClid = (): string | undefined =>
  window.__cf_analytics?.getClid?.();

export const sendEvent: SendEvent = (params, immediate) =>
  window.__cf_analytics?.sendEvent?.(params, immediate);

export const appendAdditionalProperty = (properties: {
  [propertyBagKey: string]: any;
}) => {
  if (
    typeof window === 'undefined' ||
    !window.__cf_analytics?.additionalProperties
  )
    return;
  window.__cf_analytics.additionalProperties = {
    ...window.__cf_analytics.additionalProperties,
    ...properties,
  };
};

export const acceptGdpr = (accepted: boolean) =>
  window.__cf_analytics?.gdpr?.acceptGdpr(accepted);

/*
  null - is not accepted or declined;
  true - accepted all;
  false - declined non-essential.
*/
export const isGdprAccepted = (): null | boolean =>
  window.__cf_analytics?.gdpr?.isGdprAccepted() ?? null;

export const processRestEvents = () => window.__cf_analytics?.processQueue?.();
