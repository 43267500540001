import { checkWLDomain } from './whitelabel';

// Default use second level domain
export const getTopLevelDomain = (segments: number = 2): string => {
  const { hostname } = window.location;
  // Use full hostname for WL instances
  const mainHost = checkWLDomain(hostname)
    ? hostname
    : hostname
        .split('.')
        .slice(-1 * segments)
        .join('.');
  return mainHost;
};

export const getPortWithSeparator = (): string => {
  const { port } = window.location;
  const portWithSeparator = `${port ? `:${port}` : ''}`;
  return portWithSeparator;
};

export const getApiHostname = (): string => {
  // Use stand subdomain (3 level) for development environment (space zone)
  const domainLength = window.location.hostname.endsWith('chatfuel.space')
    ? 3
    : 2;
  return `${window.location.protocol}//${getTopLevelDomain(
    domainLength,
  )}${getPortWithSeparator()}`;
};
