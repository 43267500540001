import { BASE_Z_INDEX } from '../../consts';
import { AdaptiveStyleProperties } from '../../styles';

export const WHATSAPP_POPUP_STYLE: Record<string, AdaptiveStyleProperties> = {
  logo: {
    _: {
      flexShrink: 0,
      color: '#8A92A0',
      background: '#ffffff',
      width: '100%',
      height: '56px',
      padding: '24px 0 12px',
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'center',
    },
    sm: {
      background: 'transparent',
      color: '#FFFFFF',
      width: 'auto',
      height: '20px',
      padding: '0',
    },
  },
  close: {
    _: {
      background: '#777d8a',
      padding: '6px',
      borderRadius: '50%',
      overflow: 'hidden',
      cursor: 'pointer',
      top: '12px',
      right: '12px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      position: 'absolute',
      zIndex: 1,
    },
    sm: {
      position: 'fixed',
    },
  },
  back: {
    _: {
      zIndex: BASE_Z_INDEX,
      position: 'fixed',
      inset: '0',
      background: 'rgba(12, 23, 45, 0.8)',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  centered: {
    _: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '620px',
      alignItems: 'center',
      justifyContent: 'end',
    },
    sm: {
      justifyContent: 'space-between',
      maxHeight: '100vh',
      gap: '24px',
      padding: '20px 20px 12px',
    },
  },
  modal: {
    _: {
      display: 'flex',
      alignItems: 'end',
      maxHeight: 'calc(100% - 56px)',
      width: '100%',
      justifyContent: 'center',
      fontFamily:
        "'Inter', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', Arial, sans-serif",
    },
    sm: {
      flexGrow: 1,
      alignItems: 'center',
    },
  },
  modalContent: {
    _: {
      background: '#ffffff',
      flexGrow: 1,
      borderRadius: '12px 12px 0 0',
      maxHeight: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '32px',
      position: 'relative',
    },
    sm: {
      borderRadius: '12px',
    },
    md: {
      gap: '40px',
    },
  },
  image: {
    _: {
      maxWidth: '100%',
      width: '100%',
    },
  },
  header: {
    _: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '0.4px',
      textAlign: 'center',
      marginBottom: '16px',
    },
    md: {
      fontSize: '32px',
      lineHeight: '40px',
    },
  },
  text: {
    _: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0px',
      textAlign: 'center',
    },
  },
  button: {
    _: {
      background: '#0EBC83',
      borderRadius: '8px',
      color: 'white',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '0px',
      textAlign: 'center',
      textDecoration: 'none',
      padding: '12px 16px',
      flexBasis: '100%',
    },
    sm: {
      flexBasis: '55%',
    },
    md: {
      fontSize: '24px',
    },
  },
  buttonContainer: {
    _: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0 16px 0',
    },
    sm: {
      padding: '0 20px 28px',
    },
  },
  cta: {
    _: {
      padding: '24px 16px 0',
    },
    md: {
      padding: '28px 60px 0',
    },
  },
};
