import { getTopLevelDomain } from './domain';

const allowDomainsList = ['reports.dashbot.io', 'botanalytics.co'];

if (typeof window !== 'undefined') {
  const toplevelDomain = getTopLevelDomain();
  const originalOpen = window.open;

  window.open = (url?: string | URL, target?: string, ...args) => {
    const newWindow = originalOpen(url, target, ...args);
    let skipOpenerCleanup;

    if (url) {
      try {
        const { hostname: targetHost } = new URL(url);
        skipOpenerCleanup =
          targetHost.endsWith(toplevelDomain) ||
          allowDomainsList.includes(targetHost);
      } catch {
        skipOpenerCleanup = false;
      }
    }

    if (!skipOpenerCleanup && newWindow && target === '_blank') {
      newWindow.opener = null;
    }

    return newWindow;
  };
}
