const GLOBAL_THRESHOLD = 2;
const MAX_QUEUE_SIZE = 10;
const RETRY_TIMEOUT = 1000;
const PROCESS_TIMEOUT = 5000;
const RETRY_THRESHOLD = 2;

type QueueProcessor<T> = (items: T[]) => Promise<any>;

type QueueConfig<T> = {
  processQueue: QueueProcessor<T>;
  threshold?: number;
  queueSize?: number;
  retryTimeout?: number;
  processTimeout?: number;
  retryThreshold?: number;
};

type QueueEventsHandlers = {
  onProcessed?(error: boolean): void;
  onClear?(): void;
  onPassedToProcessor?(): void;
  onFilled?(): void;
  onRetry?(): void;
  onReturnToQueue?(): void;
};

export class Queue<T = any> {
  private retries: number;
  private threshold: number;
  private queueSize: number;
  private retryTimeout: number;
  private processTimeout: number;
  private retryThreshold: number;
  private processQueue: QueueProcessor<T>;
  private eventsHandlers: QueueEventsHandlers = {};

  private queue: T[] = [];
  private syncTimeout: null | number = null;

  constructor(config: QueueConfig<T>) {
    this.processQueue = config.processQueue;
    this.threshold = config.threshold ?? GLOBAL_THRESHOLD;
    this.queueSize = config.queueSize ?? MAX_QUEUE_SIZE;
    this.retryTimeout = config.retryTimeout ?? RETRY_TIMEOUT;
    this.processTimeout = config.processTimeout ?? PROCESS_TIMEOUT;
    this.retryThreshold = config.retryThreshold ?? RETRY_THRESHOLD;
    this.retries = this.threshold;
  }

  private process(items: T[], retries: number = this.retryThreshold) {
    if (!items.length) return;

    if (this.retries <= 0) this.clear();

    if (retries <= 0) {
      this.queue.push(...items);
      this.retries -= 1;
      this.eventsHandlers.onReturnToQueue?.();
      return;
    }

    this.processQueue(items)
      .then(() => this.eventsHandlers.onProcessed?.(false))
      .catch(() => {
        this.eventsHandlers.onProcessed?.(true);

        setTimeout(() => {
          this.process(items, retries - 1);
          this.eventsHandlers.onRetry?.();
        }, this.retryTimeout);
      });
  }

  public clear(clearThreshold: boolean = true) {
    if (this.syncTimeout) clearTimeout(this.syncTimeout);
    this.queue = [];
    if (clearThreshold) this.retries = this.threshold;
    this.eventsHandlers.onClear?.();
  }

  public push(event: T, immediate?: boolean) {
    if (immediate) {
      this.process([event]);
      return;
    }

    if (this.syncTimeout) clearTimeout(this.syncTimeout);
    this.queue.push(event);

    if (this.queue.length >= this.queueSize) {
      this.process(this.queue);
      this.clear(false);
      this.eventsHandlers.onFilled?.();
      return;
    }

    this.syncTimeout = window.setTimeout(() => {
      this.process(this.queue);
      this.clear(false);
      this.eventsHandlers.onPassedToProcessor?.();
    }, this.processTimeout);
  }

  public processRest() {
    this.process(this.queue);
    this.clear();
  }

  public initEventHandlers(eventsHandlers?: QueueEventsHandlers) {
    this.eventsHandlers = eventsHandlers ?? {};
  }
}
