import { useEffect, useState } from 'react';

const cache = new Set(); // successfully loaded scripts

interface ThirdPartyScriptOptions {
  attributes?: Record<string, string>;
  skip?: boolean;
}

export const useThirdPartyScript = (
  src: string,
  options?: ThirdPartyScriptOptions,
) => {
  const { attributes = {}, skip } = options || {};
  const didResolveScript = cache.has(src);
  const [resolved, setResolved] = useState(didResolveScript);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (skip) {
      return undefined;
    }

    if (didResolveScript) {
      return undefined;
    }
    let active = true;
    const element = document.createElement('script');
    element.type = 'text/javascript';

    Object.entries(attributes).forEach(([key, value]) => {
      element.setAttribute(key, value);
    });

    element.onload = () => {
      if (!active) {
        return;
      }
      cache.add(src);
      setResolved(true);
    };
    element.onerror = () => {
      if (!active) {
        return;
      }
      setError(true);
      throw new URIError(`The script ${src} didn't load correctly.`);
    };
    element.src = src;
    document.body.appendChild(element);
    return () => {
      active = false;
    };
  }, [src, didResolveScript, skip, attributes]);

  return { resolved, error };
};
