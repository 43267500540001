import { getSharedFlowToken } from './token';

interface Response<T> {
  result: T;
  success: boolean;
}

const prepareHeaders = (headers: Headers) => {
  const token = getSharedFlowToken();
  if (token) {
    headers.append('Authorization', token);
  }
  return headers;
};

export const jsonRequest = async <T>(input: RequestInfo): Promise<T> => {
  const headers = new Headers();
  prepareHeaders(headers);
  const response = await fetch(input, { headers });
  if (response.ok) {
    const data: Response<T> = await response.json();
    if (data.success) {
      return data.result;
    }
  }
  return Promise.reject(response);
};
