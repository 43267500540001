export const findMostFrequent = (array: any[]) => {
  if (array.length === 0) {
    return null;
  }
  const frqMap: Record<any, number> = {};
  let maxFrqItem = array[0];
  let maxCount = 1;
  array.forEach((el) => {
    frqMap[el] = (frqMap[el] || 0) + 1;
    if (frqMap[el] > maxCount) {
      maxFrqItem = el;
      maxCount = frqMap[el];
    }
  });
  return maxFrqItem;
};
