export const getPageLoadingTime = (): null | number => {
  // Modern browsers
  if (window.performance?.getEntriesByType) {
    const [performanceEntry] = window.performance.getEntriesByType(
      'navigation',
    );
    return performanceEntry?.duration;
  }

  // Old browsers and Safari
  if (window.performance?.timing) {
    const { loadEventEnd, navigationStart } = window.performance?.timing;
    return loadEventEnd - navigationStart;
  }

  return null;
};
