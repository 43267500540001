export enum PluginTypes {
  sendToMessenger = 'entry_point/send_to_messenger',
  customerChat = 'entry_point/customer_chat',
  whatsappWidget = 'entry_point/whatsapp_widget',
  whatsappPopup = 'entry_point/whatsapp_popup',
}

export const FB_PLUGINS = [
  PluginTypes.customerChat,
  PluginTypes.sendToMessenger,
];

export const BASE_Z_INDEX = 2147483647;
