import { LS, LSKey } from './localStorage';

export enum GestureNavigation {
  mouse = 'mouse',
  trackpad = 'trackpad',
}

export const getNavigationType = (): GestureNavigation => {
  return (LS.getRaw(LSKey.gestureNavigationType) ||
    GestureNavigation.mouse) as GestureNavigation;
};

export const setNavigationType = (type: GestureNavigation) => {
  LS.setRaw(LSKey.gestureNavigationType, type);
};

export const isTrackpadEnabled = (): boolean => {
  return getNavigationType() === GestureNavigation.trackpad;
};

export const setTrackpadEnabled = (enabled: boolean) => {
  setNavigationType(
    enabled ? GestureNavigation.trackpad : GestureNavigation.mouse,
  );
};
