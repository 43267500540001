import { useEffect } from 'react';
import { useCallbackRef } from '../hooks';

export const usePostMessage = (messagePrefix: string, callback: (message: string) => void) => {
  const callbackRef = useCallbackRef (callback);

  useEffect(() => {
    const listener = ({data: message}: MessageEvent) => {
      if (typeof message === 'string' && message.indexOf(messagePrefix) === 0) {
        callbackRef.current(message);
      }
    };
    window.addEventListener('message', listener, false);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, [callbackRef, messagePrefix]);
};
