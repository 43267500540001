import { MutableRefObject, useEffect, useRef } from 'react';

interface EventHandler<TEvent> {
  (event: TEvent): void;
}

interface EventListenerConfig {
  options?: boolean | AddEventListenerOptions;
  htmlElement?: HTMLElement | null;
  htmlElementRef?: MutableRefObject<HTMLElement | null>;
}
export const useEventListener = <TEvent = {}>(
  eventName: string,
  handler: EventHandler<TEvent>,
  config: EventListenerConfig = {},
) => {
  const handlerRef = useRef<EventHandler<TEvent> | undefined>();
  const { htmlElement, htmlElementRef, options } = config;

  useEffect(() => {
    handlerRef.current = handler;
  }, [handler]);

  useEffect(() => {
    const element = htmlElementRef?.current ?? htmlElement ?? window.document;
    const isSupported = element.addEventListener;

    if (!isSupported) {
      return;
    }

    const eventListener = (event: any) => handlerRef.current?.(event);

    element.addEventListener(eventName, eventListener, options);

    // eslint-disable-next-line consistent-return
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, htmlElement, htmlElementRef, options]);
};
