type StorageT = Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>;

export class SchemedStorage<Schema> {
  private storage: StorageT;
  constructor(storage: StorageT) {
    this.storage = storage;
  }

  get<K extends keyof Schema>(key: K): Schema[K] | undefined {
    try {
      const localStorageValue = this.storage.getItem(key as string);
      if (localStorageValue !== null) {
        return JSON.parse(localStorageValue) as Schema[K];
      }
      return undefined;
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.warn(key, error);
      }
      return undefined;
    }
  }

  getRaw<K extends keyof Schema | string>(key: K): string | null {
    return this.storage.getItem(key as string);
  }

  set<K extends keyof Schema>(key: K, value: Schema[K]): void {
    this.storage.setItem(key as string, JSON.stringify(value));
  }

  setRaw<K extends keyof Schema | string>(key: K, value: string): void {
    this.storage.setItem(key as string, value);
  }

  has<K extends keyof Schema>(key: K): boolean {
    return this.storage.getItem(key as string) !== null;
  }

  remove<K extends keyof Schema>(key: K) {
    this.storage.removeItem(key as string);
  }
}
