const NOT_DIGIT_SYMBOLS_REGEXP = /[^0-9]+/g;

export const generateWaLinkWithMessage = (
  phoneNumber: string,
  prefilledMessage: string,
) =>
  `https://wa.me/${phoneNumber.replace(
    NOT_DIGIT_SYMBOLS_REGEXP,
    '',
  )}?${new URLSearchParams({
    text: prefilledMessage,
  }).toString()}`;

export const getLSKey = (prefix: string, entryPointId: string | null) =>
  `${prefix}${entryPointId ?? ''}`;
