import { sendBeaconOrFetch } from './beaconOrFetch';
import { getApiHostname } from './domain';
import { IS_LOCAL_MONITORING } from './environment';
import { Queue } from './queue';

const LOGGER_NAME = 'LOCAL_MONITORING';
const LOGGER_PREFIX = `[${LOGGER_NAME}] `;
const logHostname = `${getApiHostname()}/frontend_monitoring/logs`;

const localMonitoringQueue = new Queue<any>({
  threshold: 1,
  queueSize: 25,
  processQueue: (logItems) =>
    sendBeaconOrFetch(
      logHostname,
      // Log only uniq messages
      JSON.stringify(
        Array.from(new Set(logItems)).map((logData) => {
          let logToConsole = null;
          try {
            logToConsole = JSON.parse(logData);
          } catch {
            logToConsole = logData;
          } // eslint-disable-next-line no-console
          console.info(LOGGER_PREFIX, logToConsole);
          return {
            msg: logData,
            level: 'info',
            title: LOGGER_PREFIX,
          };
        }),
      ),
    ),
});

export const localMonitoring = (data: any) => {
  if (!IS_LOCAL_MONITORING) {
    return;
  }

  const logData = typeof data !== 'string' ? JSON.stringify(data) : data;

  localMonitoringQueue.push(logData);
};
