import { isMongoObjectId } from './utils/mongo/isMongoObjectId';

type LocationMetaData = {
  botId?: string;
  blockId?: string;
  tabId?: string;
};

export const getLocationData = (pathname: string): LocationMetaData => {
  const paths = pathname.split('/');
  const botIndex = paths.findIndex((p) => p === 'bot');

  if (botIndex === -1) {
    return {};
  }

  const startDataIndex = botIndex + 1;
  const endDataIndex = botIndex + 4;
  const dataParts = paths.slice(startDataIndex, endDataIndex);

  const [botId, tabId, blockId] = dataParts;

  return {
    tabId,
    botId: isMongoObjectId(botId) ? botId : undefined,
    blockId: isMongoObjectId(blockId) ? blockId : undefined,
  };
};
