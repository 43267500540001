// Alias for conversions scripts
// https://tagmanager.google.com/#/container/accounts/1548055567/containers/7125027/workspaces/270/tags/252
/* eslint-disable no-underscore-dangle */
import { fbqTrack } from './facebookPixel';

export const requestDemoEvent = () => {
  window.__cf_conversion?.requestDemoEvent();
};

export const registrationEvent = (userId: string) => {
  fbqTrack(
    'CompleteRegistration',
    {},
    {
      eventID: userId,
    },
  );
  window.__cf_conversion?.registrationEvent(userId);
};

export const connectWaPhoneEvent = (botId: string) => {
  fbqTrack(
    'StartTrial',
    {},
    {
      eventID: botId,
    },
  );
};

export const seenAtLeast1DialogEvent = (botId: string) => {
  fbqTrack(
    'SubmitApplication',
    {},
    {
      eventID: botId,
    },
  );
};

export const connectPageEvent = (botId: string) => {
  fbqTrack(
    'StartTrial',
    {},
    {
      eventID: botId,
    },
  );
};

export const addBankingCardEvent = (botOrAdminId: string) => {
  fbqTrack(
    'AddPaymentInfo',
    {},
    {
      eventID: botOrAdminId,
    },
  );
};

interface submitPaymentEventProps {
  currency: string;
  tierName: string;
  value: number;
}

export const submitPaymentEvent = (
  { currency, tierName, value }: submitPaymentEventProps = {
    currency: 'USD',
    tierName: 'PRO',
    value: 15,
  },
  botOrAdminId: string,
) => {
  fbqTrack(
    'Subscribe',
    {
      currency,
      value,
      subscription_id: tierName,
    },
    {
      eventID: botOrAdminId,
    },
  );
};
