export enum PaymentFlowErrorKind {
  stripeDecline = 'stripeDecline',
  failed3DSCheck = 'failed3DSCheck',
  unhandledError = 'unhandledError',
}

export interface PaymentFlowErrorData {
  kind: PaymentFlowErrorKind;
  declineCode?: string;
}

export class PaymentFlowError extends Error {
  private readonly errorData$: PaymentFlowErrorData;

  constructor(message: string, errorData: PaymentFlowErrorData) {
    super(message);
    this.errorData$ = errorData;
  }

  get errorData() {
    return this.errorData$;
  }
}
