import memoize from 'lodash-es/memoize';
import { sendEvent } from '../analytics';
import { runFnWhen } from '../runFnWhen';
import { loadScript } from '../scriptsLoader';
import { globalConfigAsync } from '@utils/globalConfigAsync';

export enum GoogleScope {
  googleSheet = 'https://www.googleapis.com/auth/spreadsheets',
  profile = 'profile',
  email = 'email',
}

export const getGoogleScope = (...scopes: GoogleScope[]) => scopes.join(' ');

const GOOGLE_SDK_SRC_DEFAULT =
  'https://apis.google.com/js/client:platform.js?onload=googleapioninit';

export const initGoogleApi = async () => {
  const { GOOGLE_SDK_SRC } = await globalConfigAsync;
  loadScript(GOOGLE_SDK_SRC || GOOGLE_SDK_SRC_DEFAULT, 'google_api');
};

const GOOGLE_CLIENT_ID =
  '723102250220-au9q7l1tq75fkt2rj2mv7vueafba0s4t.apps.googleusercontent.com';

type GetGoogleInstanceParams = Omit<AuthInitParams, 'client_id'>;

export const getGoogleAuthInstance = ({
  scope,
}: GetGoogleInstanceParams = {}) =>
  window.gapi?.auth2?.init({
    client_id: GOOGLE_CLIENT_ID,
    scope,
  });

export const getGoogleAuthInstanceAsync = memoize(
  ({ scope }: GetGoogleInstanceParams = {}): Promise<GoogleAuth> =>
    new Promise((resolve, reject) => {
      runFnWhen(
        async () => {
          const authInstance = window.gapi?.auth2?.getAuthInstance();
          if (authInstance) {
            if (scope) {
              const grantedPermissions =
                authInstance.currentUser.get().getGrantedScopes()?.split(' ') ??
                [];
              const neededPermissions = scope
                .split(' ')
                .filter((s) => !grantedPermissions.includes(s));

              if (neededPermissions.length) {
                try {
                  await authInstance.grant({
                    scope: neededPermissions.join(' '),
                  });
                } catch (error) {
                  console.error(error);
                  reject(error);
                }
              }
            }

            resolve(authInstance);
          } else {
            resolve(getGoogleAuthInstance({ scope })!);
          }
        },
        () => !!(window.gapi && window.gapi.auth2 && window.gapi.auth2.init),
        50,
      );
    }),
);

const GET_GOOGLE_USER_ID_TIMEOUT = 10000;

export const GET_USER_ID_ERROR = new Error('timout wait google user id');

export const getGoogleUserId = (googleAuthInstance: GoogleAuth) =>
  new Promise<string>((resolve, reject) => {
    const timeout = window.setTimeout(() => {
      reject(GET_USER_ID_ERROR);
    }, GET_GOOGLE_USER_ID_TIMEOUT);
    const interval = window.setInterval(() => {
      const id = googleAuthInstance.currentUser
        .get()
        .getBasicProfile()
        ?.getId();
      if (id) {
        resolve(id);
        window.clearInterval(interval);
        window.clearTimeout(timeout);
      }
    }, 100);
  });

export const goToChatfuelWithGoogleUserData = (
  href: string,
  googleUser?: GoogleUser,
) => {
  if (googleUser && typeof googleUser.getAuthResponse === 'function') {
    const token = googleUser.getAuthResponse().id_token;
    const email = googleUser.getBasicProfile().getEmail();
    const username = googleUser.getBasicProfile().getName();

    const url = new URL(href);
    url.searchParams.set('id_token', token);
    url.searchParams.set('google_email', email);
    url.searchParams.set('google_username', username);

    sendEvent({
      category: 'sign up',
      action: 'choose google account',
      propertyBag: { email },
    });

    window.location.href = url.toString();
  }
};

export const goToChatfuelWithGoogleAuth = (
  googleAuthInstance: GoogleAuth,
  href: string,
) => {
  googleAuthInstance
    .signIn({
      prompt: 'select_account',
      scope: getGoogleScope(GoogleScope.profile, GoogleScope.email),
      ux_mode: 'popup',
      redirect_uri: 'https://chatfuel.com/oauth/',
    })
    .then((googleUser) => {
      goToChatfuelWithGoogleUserData(href, googleUser);
    });
};
