import { useEffect } from 'react';
import { isWebGLSupported } from '@pixi/utils';
import { sendEvent } from '../analytics';
import { sendAdBlockerAnalytics } from '../adblockers';

export const useDeviceMetrics = () => {
  useEffect(() => {
    sendEvent({
      action: isWebGLSupported() ? 'supported' : 'unsupported',
      category: 'webgl',
    });

    sendAdBlockerAnalytics();
  }, []);
};
