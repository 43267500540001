import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { IS_PRODUCTION_BUILD } from '../environment';
import { log } from '../logger';

export function useComponentWillUnmount(
  componentWillUnmountFunction: () => void,
): void {
  const ref = useRef<() => void>(componentWillUnmountFunction);
  useEffect(() => {
    ref.current = componentWillUnmountFunction;
  }, [componentWillUnmountFunction]);
  useEffect(() => {
    return () => ref.current();
  }, []);
}

/**
 * hook will call callback after delay
 * @param {function} callback
 * @param {ms} delay ms
 * @returns handle for clearTimeout - In case you want to manually clear the timeout
 */
export function useTimeout(
  callback: () => void,
  delay: number,
): number | undefined {
  const timeoutRef = useRef<number>();
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    timeoutRef.current = window.setTimeout(callbackRef.current, delay);
    return () => window.clearTimeout(timeoutRef.current);
  }, [delay]);

  return timeoutRef.current;
}

/**
 * keep previous value
 * @param {T} value
 * @param {T | undefined} initialValue
 */
export function usePrevious<T>(
  value: T,
  initialValue: T | undefined = undefined,
): T | undefined {
  const ref = useRef<T | undefined>(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * keep state value for one render (useful for autofocus)
 * @param {T} initialValue
 */
export function useOneTimeState<T>(
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [value, initialValue]);

  return [value, setValue];
}

/**
 * Сохраняем коллбэк в рефе, чтобы использовать актуальную версию
 * в эффектах или мемоизованных коллбэках
 *
 * WARNING: по возможности не следует использовать этот хук, предпочитайте useCallback
 */
export function useCallbackRef<T>(callback: T): MutableRefObject<T> {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return callbackRef;
}

type IsMountedFunction = () => boolean;

const isMountedUninitialized: IsMountedFunction = () => {
  if (IS_PRODUCTION_BUILD) {
    log.warn({ msg: 'isMounted function has not been initialized yet' });
  } else {
    throw new Error('isMounted function has not been initialized yet');
  }

  return false;
};

/**
 * Возвращаем ref на функцию возвращающую статус компонента:
 *  `true` - компонент замаунчен, можно делать `setState`, работать с рефами на DOM и тд
 *  `false` - компонент размаучен
 *
 * Удобно использовать в асинхронных колбеках, пример
 * ```
 *   // используем реф, так как функция инициализируется только после рендера компонента
 *   const isMountedRef = useIsMounted();
 *
 *   const callback = async () => {
 *     const isMounted = isMountedRef.current;
 *     ...
 *     // await smth
 *     if (!isMounted()) return;
 *     // now you can safely use set state
 *   }
 * ```
 */
export function useIsMounted(): MutableRefObject<IsMountedFunction> {
  const ref = useRef<IsMountedFunction>(isMountedUninitialized);

  useLayoutEffect(() => {
    let mounted = true;
    ref.current = () => mounted;
    return () => {
      mounted = false;
    };
  }, []);

  return ref;
}
