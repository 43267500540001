export const scrollIntoViewIfNeeded = (
  target: HTMLDivElement,
  toTop: boolean = false,
) => {
  if (target.getBoundingClientRect().bottom > window.innerHeight) {
    target.scrollIntoView(toTop);
  }

  if (target.getBoundingClientRect().top < 0) {
    target.scrollIntoView();
  }
};
