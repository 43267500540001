import { LSKey, LS } from './localStorage';

export const IS_PRODUCTION_BUILD = process.env.NODE_ENV === 'production';

const LS_DEBUG_VALUE = typeof window !== 'undefined' && LS.get(LSKey.isDebug);
export const IS_DEBUG = window.location.hostname.endsWith('chatfuel.space')
  ? LS_DEBUG_VALUE === undefined || LS_DEBUG_VALUE
  : LS_DEBUG_VALUE;

export const IS_LOCAL_MONITORING =
  typeof window !== 'undefined' && !!LS.getRaw(LSKey.isLocalMonitoring);

export const IS_CHROME_BROWSER =
  typeof window !== 'undefined' && navigator.userAgent.includes('Chrome');
