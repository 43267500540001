export const setFlowBuilderLoadingState = (
  flowId: string | undefined,
  loadingState: string,
): void => {
  if (!window?.flowBuilderLoadingState) {
    window.flowBuilderLoadingState = {};
  }
  if (flowId) {
    window.flowBuilderLoadingState[flowId] = loadingState;
  }
};
