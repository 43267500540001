import { useState } from 'react';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentFlowError, PaymentFlowErrorKind } from '../errors';

export const useStripeCardConfirmation = (cardNumberElementIsUsed = false) => {
  const [link3DSecure, setLink3DSecure] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const stripeIsReady = !!stripe && !!elements;

  const setupCard = async (clientSecret: string) => {
    const cardElement = cardNumberElementIsUsed
      ? elements?.getElement('cardNumber')
      : elements?.getElement('card');

    const { setupIntent, error } = await stripe!.confirmCardSetup(
      clientSecret,
      {
        payment_method: { card: cardElement! },
        return_url: `${window.location.origin}/confirm-payment-completed.html`,
        // return_url: 'https://dashboard-6.chatfuel.space/confirm-payment-completed.html',
      },
      { handleActions: false },
    );

    const link3DSecure = setupIntent?.next_action?.redirect_to_url?.url;

    if (setupIntent?.status === 'requires_action' && !!link3DSecure) {
      setLink3DSecure(link3DSecure);
      return {
        redirectIsRequired: true,
        link3DSecure,
      };
    }

    if (!!setupIntent && !error) {
      return {
        redirectIsRequired: false,
        paymentMethodId: setupIntent.payment_method,
      };
    }

    throw new PaymentFlowError(error!.message!, {
      kind: PaymentFlowErrorKind.stripeDecline,
      declineCode: error!.decline_code || error!.code,
    });
  };

  const retryCard = async (clientSecret: string) => {
    setLink3DSecure('');
    const { setupIntent, error } = await stripe!.retrieveSetupIntent(
      clientSecret,
    );

    if (!setupIntent?.payment_method) {
      throw new PaymentFlowError(
        window.i18next.t(
          'useStripeCardConfirmation-string-4024-failed-3-d-secure-check',
        ),
        {
          kind: PaymentFlowErrorKind.failed3DSCheck,
        },
      );
    }

    if (!!setupIntent && !error) {
      return {
        paymentMethodId: setupIntent.payment_method!,
      };
    }

    throw new PaymentFlowError(error!.message!, {
      kind: PaymentFlowErrorKind.stripeDecline,
      declineCode: error!.decline_code || error!.code,
    });
  };

  return {
    stripeIsReady,
    setupCard,
    retryCard,
    link3DSecure,
  };
};
