import invert from 'lodash-es/invert';
import { sendBeaconOrFetch } from './beaconOrFetch';
import { getApiHostname } from './domain';
import { Queue } from './queue';
import { getLocationData } from './locationData';
import { getClid } from './analytics';
import { getUserId } from './userId';
import { IS_PRODUCTION_BUILD } from './environment';

// Winston logging levels
// https://github.com/winstonjs/winston#logging-levels
export enum Level {
  error = 0,
  warn = 1,
  info = 2,
  verbose = 3,
  debug = 4,
  silly = 5,
}
type LogLevel = keyof typeof Level;
const LevelIndex = invert(Level);

const apiHostname = getApiHostname();

const loggerConfig = {
  title: 'log',
};
export const configureLogger = (config: Partial<typeof loggerConfig>) =>
  Object.entries(config).forEach(([key, value]) => {
    // @ts-ignore
    loggerConfig[key] = value;
  });

export interface LogParamsBase {
  error?: Error | unknown;
  msg?: string;
  data?: any;
}

interface LogParams extends LogParamsBase {
  level: Level;
}

interface LogItem extends LogParams {
  page?: string;
  location?: string;
  botId?: string;
  blockId?: string;
  tabId?: string;
  clid?: string;
  userId?: string | null;
  configBuildDate: string;
  title: string;
}

const loggerQueue = new Queue<LogItem>({
  threshold: 1,
  queueSize: 25,
  processQueue: (logItems) =>
    sendBeaconOrFetch(
      `${apiHostname}/frontend_monitoring/logs`,
      JSON.stringify(
        logItems.map((logItem) => ({
          ...logItem,
          level: LevelIndex[logItem.level],
        })),
      ),
    ),
});

export const processRestLogs = () => loggerQueue.processRest();

export const logLocally = (level: Level, msg: any | Error, data?: any) => {
  const logLevel =
    IS_PRODUCTION_BUILD && window.CHATFUEL_CONFIG
      ? Level[window.CHATFUEL_CONFIG.LOG_LEVEL_CONSOLE as LogLevel]
      : Infinity;

  if (!logLevel || level > logLevel) {
    return;
  }

  /* eslint-disable no-console */
  if (level === Level.error) {
    console.error(msg, data);
  } else if (level === Level.warn) {
    console.warn(msg, data);
  } else if (level === Level.info) {
    console.info(msg, data);
  } else {
    console.debug(msg, data);
  }
  /* eslint-enable no-console */
};

type Log = {
  (params: LogParams): void;
} & Record<LogLevel, (params: LogParamsBase) => void>;

const transformToString = (data: any): string => {
  if (data instanceof Error) {
    return data.toString();
  }

  if (typeof data === 'object') {
    return JSON.stringify(data, null, 2);
  }

  return data?.toString();
};

function isError(error: unknown): error is Error {
  if (typeof error !== 'object' || error === null) return false;
  if (!('name' in error) || !('message' in error)) return false;
  // @ts-ignore
  return typeof error.name === 'string' && typeof error.message === 'string';
}

export const log = ((params) => {
  const {
    msg,
    data,
    level = Level.info,
  } = 'error' in params && isError(params.error)
    ? {
        level: params.level || Level.error,
        msg: `logged error: ${params.msg || 'no message'}`,
        data: JSON.stringify({
          ...(typeof params.data === 'object'
            ? params.data
            : { data: params.data }),
          stack: params.error!.stack,
          name: params.error!.name,
          message: params.error!.message,
        }),
      }
    : params;

  const { href, pathname } = window.location;
  const { botId, blockId, tabId } = getLocationData(pathname);
  const logLevel = window.CHATFUEL_CONFIG
    ? Level[window.CHATFUEL_CONFIG.LOG_LEVEL_SEND as LogLevel]
    : 'silly';

  const itemToLog = {
    msg: transformToString(msg),
    data: transformToString(data),
    level,
    botId,
    tabId,
    blockId,
    location: href,
    clid: getClid(),
    userId: getUserId(),
    title: loggerConfig.title,
    configBuildDate: window.CHATFUEL_CONFIG
      ? window.CHATFUEL_CONFIG.CONFIG_BUILD_DATE
      : new Date().toString(),
  };

  if ('error' in params) {
    logLocally(level, params.error!);
  } else {
    logLocally(level, msg, data);
  }

  if (!logLevel || level > logLevel) {
    return;
  }

  if (typeof window.CHATFUEL_CONFIG?.LOG_LEVEL_SEND !== 'undefined') {
    loggerQueue.push(itemToLog);
  }
}) as Log;

// Creates simple wrapper to use log without passing Level
// log.[[level]](params)
// log.error({ msg: "Something went wrong" })
Object.keys(Level)
  .filter((l): l is LogLevel => Number.isNaN(Number(l)))
  .forEach((logLevel) => {
    log[logLevel] = (params) =>
      log({
        ...params,
        level: Level[logLevel],
      });
  });

export const subscribeOnErrors = () => {
  window.addEventListener('error', (errorEvent) => {
    log({
      level: Level.error,
      msg: 'unhandled error',
      error: errorEvent.error,
      data: {
        file: errorEvent.filename,
        line: errorEvent.lineno,
      },
    });
  });
  window.addEventListener('unhandledrejection', (errorEvent) => {
    log({
      level: Level.error,
      msg: errorEvent.reason || 'unhandled promise rejection',
    });
  });
};
