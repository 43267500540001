import React, { useEffect, useRef, useState } from 'react';
import { generateWaLinkWithMessage, getLSKey } from '../../utils';
import { WHATSAPP_POPUP_STYLE } from './whatsappPopupStyles';
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as Close } from './close.svg';
import { adaptiveStyle } from '../../styles';

const LS_POPUP_KEY_PREFIX = '__cf_wa_popup_state__';

export const WhatsappPopup = ({
  config,
  phoneNumber,
  entryPointId,
  previewMode,
  onClose,
}: {
  previewMode?: boolean;
  onClose?: () => void;
  entryPointId: string | null;
  phoneNumber: string | null;
  config: {
    buttonText: string;
    delay_s: number;
    header: string | null;
    image: string | null;
    prefilledMessage: string;
    text: string | null;
  };
}) => {
  const [isVisible, setVisible] = useState(false);
  const stylesRef = useRef<HTMLStyleElement>();

  useEffect(() => {
    setTimeout(
      () => {
        // eslint-disable-next-line chatfuel/no-use-localStorage
        const wasShown = !!window.localStorage.getItem(
          getLSKey(LS_POPUP_KEY_PREFIX, entryPointId),
        );
        if (wasShown) {
          return;
        }

        stylesRef.current = document.createElement('style');
        stylesRef.current.innerText = `.cf-no-scroll { overflow: hidden; position: fixed; } #cf-wa-popup * { box-sizing: border-box; }`;
        document.head.appendChild(stylesRef.current);
        document.body.classList.add('cf-no-scroll');
        setVisible(true);

        if (!previewMode && process.env.NODE_ENV !== 'development') {
          // eslint-disable-next-line chatfuel/no-use-localStorage
          window.localStorage.setItem(
            getLSKey(LS_POPUP_KEY_PREFIX, entryPointId),
            'true',
          );
        }
      },
      previewMode || process.env.NODE_ENV === 'development'
        ? 0
        : config.delay_s * 1000,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (previewMode) {
      onClose?.();
    } else {
      setVisible(false);
    }
    document.body.classList.remove('cf-no-scroll');
    stylesRef.current?.remove();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div
      style={adaptiveStyle(WHATSAPP_POPUP_STYLE.back)}
      id="cf-wa-popup"
      aria-modal="true"
    >
      <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.centered)}>
        <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.modal)}>
          <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.modalContent)}>
            <button
              onClick={handleClose}
              style={adaptiveStyle(WHATSAPP_POPUP_STYLE.close)}
            >
              <Close />
            </button>

            <div>
              {config.image && (
                <img
                  style={adaptiveStyle(WHATSAPP_POPUP_STYLE.image)}
                  src={config.image}
                  alt={config.header || ''}
                />
              )}

              <div
                style={
                  config.header || config.text
                    ? adaptiveStyle(WHATSAPP_POPUP_STYLE.cta)
                    : undefined
                }
              >
                {config.header && (
                  <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.header)}>
                    {config.header}
                  </div>
                )}

                {config.text && (
                  <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.text)}>
                    {config.text}
                  </div>
                )}
              </div>
            </div>

            {phoneNumber && (
              <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.buttonContainer)}>
                <a
                  data-testid="chatfuel__whatsapp-popup__cta"
                  style={adaptiveStyle(WHATSAPP_POPUP_STYLE.button)}
                  target="_blank"
                  href={generateWaLinkWithMessage(
                    phoneNumber,
                    config.prefilledMessage,
                  )}
                  rel="noopener noreferrer"
                >
                  {config.buttonText}
                </a>
              </div>
            )}
          </div>
        </div>

        <div style={adaptiveStyle(WHATSAPP_POPUP_STYLE.logo)}>
          <Logo data-testid="chatfuel__whatsapp-popup__logo" />
        </div>
      </div>
    </div>
  );
};
