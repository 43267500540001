export const runFnWhen = (
  fn: () => void,
  whenFn: () => boolean,
  delay: number,
  retryThreshold: number = Infinity,
) => {
  if (retryThreshold <= 0) {
    return;
  }

  if (whenFn()) {
    fn();
  } else {
    setTimeout(() => {
      runFnWhen(fn, whenFn, delay, retryThreshold - 1);
    }, delay);
  }
};
