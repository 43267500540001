import { PrometheusMetric } from './prometheus';
import { MetricName } from './names';
import { PrometheusType } from './types';

export const flowBuilderRenderTime = PrometheusMetric.create(
  PrometheusType.Histogram,
  MetricName.FlowBuilderRenderTime,
  'Flow render time',
);

export const dashboardLoadingTime = PrometheusMetric.create(
  PrometheusType.Histogram,
  MetricName.DashboardLoadingTime,
  'Time to remove dashboard loader',
);

export const dashboardWebVitals = PrometheusMetric.create(
  PrometheusType.Histogram,
  MetricName.DashboardWebVitals,
  'Dashboard Web Vitals',
);

export const flowBuilderFPS = PrometheusMetric.create(
  PrometheusType.Histogram,
  MetricName.FlowBuilderFPS,
  'Ticker FPS from flow builder',
);
