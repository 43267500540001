import { CounterValue, HistogramValue, Labels, Metric } from 'promjs';
import { IS_LOCAL_MONITORING } from '../environment';
import { MetricCalculation, PrometheusType } from './types';

export const transformMetricsToDTO = (
  metrics: Record<PrometheusType, MetricCalculation>,
): {
  labels: Labels[] | undefined;
  value?: number;
  values?: number[];
  help: string;
  type: string;
  name: string;
}[] => {
  const metricsFlatList = Object.values(metrics).flatMap((metric) =>
    Object.entries(metric),
  );
  return metricsFlatList.flatMap(([name, calculation]) =>
    // @ts-ignore to read internal private field to convert it to own format
    (calculation?.instance?.data ?? [])
      .map((data: Metric<HistogramValue> | Metric<CounterValue>) => ({
        labels: data.labels,
        value: typeof data.value === 'object' ? data.value.raw : data.value,
      }))
      .filter(({ value }: { value: number | number[] }) =>
        Array.isArray(value) ? value.length : value,
      )
      .map((meta: { labels: Labels; value: number | number[] }) => ({
        labels: meta.labels
          ? Object.entries(meta.labels).map(([name, value]) => ({
              name,
              value,
            }))
          : undefined,
        ...(Array.isArray(meta.value) ? {} : { value: meta.value }),
        ...(Array.isArray(meta.value) ? { values: meta.value } : {}),
        name: IS_LOCAL_MONITORING ? `LM_${name}` : name,
        help: calculation.help,
        type: calculation.type.toUpperCase(),
        // @ts-ignore to read internal private field to convert it to own format
        buckets: calculation.instance.buckets,
      })),
  );
};
