import { LSKey, LS } from './localStorage';

const PRODUCTION_DOMAIN = 'chatfuel.com';
const CHATFUEL_DOMAINS = [PRODUCTION_DOMAIN, 'chatfuel.space'];
const WL_DOMAINS = ['wl.chatfuel.com', 'wl.chatfuel.space'];

export const checkWLDomain = (hostname: string): boolean => {
  if (WL_DOMAINS.includes(hostname) || LS.getRaw(LSKey.WL_DOMAIN_MOCK))
    return true;

  const currentDomain2Level = hostname.split('.').slice(-2).join('.');
  return (
    currentDomain2Level !== 'localhost' &&
    !CHATFUEL_DOMAINS.includes(currentDomain2Level)
  );
};

export const isProductionDomain = () =>
  window.location.hostname.endsWith(PRODUCTION_DOMAIN);
