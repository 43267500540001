import { getPageLoadingTime } from '../pageLoadingTime';
import {
  dashboardLoadingTime,
  dashboardWebVitals,
  collectWebVitalsMetrics,
} from '../metrics';

export const removeGlobalLoader = () => {
  const loader = document.getElementById('js-global-loader');
  if (loader) {
    loader.remove();

    const loadingTime = getPageLoadingTime();
    if (loadingTime) {
      dashboardLoadingTime.observe(loadingTime);
    }

    collectWebVitalsMetrics(dashboardWebVitals);
  }
};
