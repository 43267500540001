const DEFAULT_COOKIE_LIFE = 185.5 * 86400000; // halfyear

export class DomainCookie {
  constructor(private domain: string) {}

  set(
    name: string,
    value: string,
    cookieTimeToLive: number = DEFAULT_COOKIE_LIFE,
  ) {
    const cookieDate = new Date(Date.now() + cookieTimeToLive);
    document.cookie = `${name}=${value}; path=/; domain=.${
      this.domain
    }; expires=${cookieDate.toUTCString()}`;
  }

  delete(name: string) {
    document.cookie = `${name}=""; path=/; domain=.${this.domain}; max-age=-1`;
  }

  // eslint-disable-next-line class-methods-use-this
  get(name: string): string | null {
    const matches = document.cookie.match(
      new RegExp(
        `(?:^|; )${name.replace(
          // eslint-disable-next-line no-useless-escape
          /([\.$?*|{}\(\)\[\]\\\/\+^])/g,
          '\\$1',
        )}=([^;]*)`,
      ),
    );
    return matches ? decodeURIComponent(matches[1]) : null;
  }
}
