import { nanoid } from 'nanoid/non-secure';
import { HistogramType } from 'promjs';
import { useEffect, useRef } from 'react';
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

const getCurrentTime = () => Date.now();

const timers: Record<string, number> = {};

export const diffTimer = (timerName: string) => {
  const currentTime = getCurrentTime();

  if (timers[timerName]) {
    return currentTime - timers[timerName];
  }

  timers[timerName] = currentTime;

  return null;
};

export const timeDiff = () => {
  const currentTime = getCurrentTime();
  return () => getCurrentTime() - currentTime;
};

export const getPaintTimings = (): [string, number][] | null => {
  if (!window.performance?.getEntriesByType) {
    return null;
  }

  return window.performance
    .getEntriesByType('paint')
    .map(({ name, startTime }) => [name, startTime]);
};

export class CalculationList {
  private calculations: number[] = [];

  private isValidMetrics(
    metrics: Record<'min' | 'max' | 'avg' | 'median', number>,
  ): boolean {
    return (
      Number.isFinite(metrics.min) &&
      Number.isFinite(metrics.max) &&
      !Number.isNaN(metrics.avg) &&
      !Number.isNaN(metrics.median)
    );
  }

  public clean() {
    this.calculations = [];
  }

  public add(calculation: number) {
    this.calculations.push(calculation);
  }

  public max(): number {
    return Math.round(Math.max(...this.calculations));
  }

  public min(): number {
    return Math.round(Math.min(...this.calculations));
  }

  public avg(): number {
    const sum = this.calculations.reduce((acc, num) => acc + num, 0);
    return Math.round(sum / this.calculations.length);
  }

  public median(): number {
    const sortedCalculations = this.calculations.slice().sort((a, b) => a - b);
    const halfIndex = Math.floor(sortedCalculations.length / 2);

    if (sortedCalculations.length % 2) {
      return Math.round(sortedCalculations[halfIndex]);
    }

    return Math.round(
      (sortedCalculations[halfIndex - 1] + sortedCalculations[halfIndex]) / 2,
    );
  }

  public metrics(): null | Record<'min' | 'max' | 'avg' | 'median', number> {
    const metrics = {
      min: this.min(),
      max: this.max(),
      avg: this.avg(),
      median: this.median(),
    };
    return this.isValidMetrics(metrics) ? metrics : null;
  }
}

export const useLoadingTimeMetric = (
  histogramMetric: HistogramType,
  hasLoaded: boolean,
) => {
  const randomMetricId = useRef<string>(nanoid());

  useEffect(() => {
    diffTimer(randomMetricId.current);
  }, []);

  useEffect(() => {
    if (!hasLoaded) {
      return;
    }

    const loadingTime = diffTimer(randomMetricId.current);

    if (loadingTime) {
      histogramMetric.observe(loadingTime);
    }
  }, [histogramMetric, hasLoaded]);
};

export const collectWebVitalsMetrics = (metric: HistogramType) => {
  const onCollect = ({ name, value }: { name: string; value: number }) =>
    metric.observe(value, { vital: name });

  getCLS(onCollect);
  getFCP(onCollect);
  getFID(onCollect);
  getLCP(onCollect);
  getTTFB(onCollect);
};
