// Use default promise syntax instead of async/await to cut off Babel regeneratorRuntime from the libraries

type BodyType = string | Blob;

const sendWithFetch = (url: string, body: BodyType) =>
  new Promise<void>((res, rej) => {
    fetch(url, {
      method: 'POST',
      body,
    }).then((response) => {
      if (response.status !== 200) {
        rej();
      } else {
        res();
      }
    });
  });

const sendWithBeacon = (url: string, body: BodyType) =>
  new Promise<void>((res, rej) => {
    // Beacons always should be sent using https
    const added = navigator.sendBeacon(url, body);

    if (!added) {
      rej();
    } else {
      res();
    }
  });

export const sendBeaconOrFetch = (url: string, body: BodyType) =>
  typeof navigator.sendBeacon === 'function' && url.startsWith('https:')
    ? sendWithBeacon(url, body)
    : sendWithFetch(url, body);
