import {
  FlowTypes,
  FlowData,
} from 'cf-dashboard/src/modern-components/FlowBuilder/types';
import { jsonRequest } from '../utils/request';

interface AggregatedFlowGroup {
  id: string;
  title: string;
  bot_status: FlowTypes.AggregatedFlowQuery_bot_status;
  flows: FlowDictionary[];
}

export type FlowDictionary = Pick<FlowData, 'id' | 'title' | 'platform'>;

export type FlowGroupData = Omit<AggregatedFlowGroup, 'bot_status'> & {
  botStatus: FlowTypes.AggregatedFlowQuery_bot_status;
};

export const getFlowGroup = async (flowGroupId: string) => {
  const {
    id,
    title,
    flows,
    bot_status: botStatus,
  } = await jsonRequest<AggregatedFlowGroup>(
    `/api/shared/aggregated_flow_group/${flowGroupId}`,
  );

  return {
    id,
    title,
    botStatus,
    flows,
  };
};
