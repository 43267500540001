const scriptsPromises: Record<string, Promise<void>> = {};

export const loadScript = (url: string, tagId?: string) => {
  if (url in scriptsPromises) {
    return scriptsPromises[url];
  }

  scriptsPromises[url] = new Promise<void>((res, rej) => {
    const tag = document.createElement('script');
    tag.setAttribute('src', url);

    if (tagId) {
      tag.id = tagId;
    }

    tag.onload = () => res();
    tag.onerror = () => rej();

    document.head.append(tag);
  });

  return scriptsPromises[url];
};
