import scrollIntoView, { Options } from 'scroll-into-view-if-needed';
import { useCallback, useRef } from 'react';

export const useScrollIntoView = <T extends HTMLElement = HTMLDivElement>(
  options?: Options,
) => {
  const scrollTargetRef = useRef<T | null>(null);
  const scrollToTarget = useCallback(() => {
    if (!scrollTargetRef.current) {
      return;
    }
    scrollIntoView(scrollTargetRef.current, options);
  }, [options]);

  return {
    scrollToTarget,
    scrollTargetRef,
  };
};
